<template>
  <div>
    <div class="d-flex justify-center">
      <div v-if="worksheet.jobId" class="text-center font-weight-light">
        {{ $vuetify.lang.t("$vuetify.worksheet.job") }}: <a @click="assignJob">{{ worksheet.jobName }}</a>
      </div>
      <div v-else>
        <v-btn @click="assignJob" text>{{ $vuetify.lang.t("$vuetify.worksheet.assign2Job") }}</v-btn>
      </div>
    </div>
    <v-subheader class="d-flex justify-space-between pt-4">
      <div v-if="worksheetTypeFields.hasTargetWeight">
        <v-btn
          :color="remainingWeight > 0 ? 'red' : 'green'"
          text
          @click="showWeightStatus = true"
        >
          <div
            v-if="remainingWeight >= 0"
            class="d-flex flex-column flex-sm-row"
          >
            <span
              >{{ remainingWeight | formatNumber }}
              {{ productInfo ? productInfo.measureWeight : "" }}
            </span>
            <span>{{ $vuetify.lang.t("$vuetify.base.remain") }}</span>
          </div>
          <div v-else class="d-flex flex-column flex-sm-row">
            <span
              >{{ Math.abs(remainingWeight) | formatNumber }}
              {{ productInfo ? productInfo.measureWeight : "" }}
            </span>
            <span> {{ $vuetify.lang.t("$vuetify.base.over") }}</span>
          </div>
        </v-btn>
        <v-snackbar
          v-model="showWeightStatus"
          :timeout="timeout"
          color="primary"
          multi-line
        >
          <span v-if="remainingWeight >= 0">{{ targetRemainStatus }}</span>
          <span v-else>{{ targetOverStatus }}</span>

          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="showWeightStatus = false" dark>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </div>
      <div class="text-h5 font-weight-bold" v-else>
        {{ sum.netWeight | formatNumber }}
        {{ productInfo ? productInfo.measureWeight : "" }}
      </div>
      <v-divider v-if="worksheet.locationName" vertical />
      <div class="text-right">
        <div v-if="worksheet.vehiclePlateNumber">
          {{ worksheet.vehiclePlateNumber }}
        </div>
        <div v-if="worksheet.locationName">{{ worksheet.locationName }}</div>
      </div>
    </v-subheader>

    <custom-dialog :fullscreen="true" v-model="assignJobDialog">
      <template slot="title">
        Assign worksheet {{ worksheet.id }} to Job
      </template>
      <template slot="default">
        <choose-job @deleted="jobDeleted" @selected="updateWorksheetJob" v-model="worksheet.jobId"/>
      </template>
    </custom-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const CustomDialog = () => import("@/components/common/CustomDialog.vue");
const ChooseJob = () => import("@/components/job/ChooseJob.vue");

export default {
  props: {
    worksheet: {
      type: Object,
      required: true,
    },
    worksheetTypeFields: {
      type: Object,
      required: true,
    },
    sum: {
      type: Object,
      required: true,
    },
    productInfo: {
      type: Object,
      required: false,
    },
  },
  components: { CustomDialog, ChooseJob },
  data: () => {
    return {
      showWeightStatus: false,
      timeout: 5000,
      assignJobDialog: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    remainingWeight() {
      const target = this.worksheet.targetWeight
        ? this.worksheet.targetWeight
        : 0;
      const total = this.sum.netWeight;
      const remainingWeight = target - total;
      return remainingWeight;
    },
    targetRemainStatus() {
      const weightUnit = this.productInfo ? this.productInfo.measureWeight : "";
      const remaining = `${this.$options.filters.formatNumber(
        this.remainingWeight
      )} ${weightUnit}`;
      const netWeight = `${this.$options.filters.formatNumber(
        this.sum.netWeight
      )} ${weightUnit}`;
      const targetWeight = `${this.$options.filters.formatNumber(
        this.worksheet.targetWeight
      )} ${weightUnit}`;
      return this.$vuetify.lang.t(
        "$vuetify.worksheet.targetRemainStatus",
        remaining,
        netWeight,
        targetWeight
      );
    },
    targetOverStatus() {
      const weightUnit = this.productInfo ? this.productInfo.measureWeight : "";

      const remaining = `${this.$options.filters.formatNumber(
        Math.abs(this.remainingWeight)
      )} ${weightUnit}`;
      const netWeight = `${this.$options.filters.formatNumber(
        this.sum.netWeight
      )} ${weightUnit}`;
      const targetWeight = `${this.$options.filters.formatNumber(
        this.worksheet.targetWeight
      )} ${weightUnit}`;
      return this.$vuetify.lang.t(
        "$vuetify.worksheet.targetOverStatus",
        remaining,
        netWeight,
        targetWeight
      );
    },
  },
  created() {},
  methods: {
    assignJob() {
      this.assignJobDialog = true;
    },
    updateWorksheetJob(selectedJob) {
      this.assignJobDialog = false;
      const params = {
        jobId: selectedJob ? selectedJob.id:null
      }
      this.$axios.put('/processWorksheet/assign-job/' + this.worksheet.id, null, {params})
        .then( (resp) => {
          this.worksheet.jobId = selectedJob ? selectedJob.id:null;
          this.worksheet.jobName = selectedJob ? selectedJob.name:null;
          this.$emit('jobUpdated')
        })
    },
    jobDeleted(){
      this.$emit('jobUpdated')
    }
  },
};
</script>

<style></style>
